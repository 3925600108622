import React, { useState, useEffect } from 'react';
import config from '../config';
import { fetchWithAuth } from '../utils/api';  
import CreateCustomer from './CreateCustomer'; 

const Modal = ({ show, handleClose, contact, clientData, noClientFound, onCreateCustomer, onSelectClient }) => {
  const handleOutsideClick = (e) => {
    if (e.target.id === "modal-overlay") {
      handleClose();
    }
  };
  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';  // Disable scroll
    } else {
      document.body.style.overflow = 'auto';    // Enable scroll when modal is closed
    }
  }, [show]);

  return (
    <div
      id="modal-overlay"
      className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center ${show ? 'block' : 'hidden'}`}
      onClick={handleOutsideClick}
    >
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-3xl lg:max-w-4xl h-3/4 lg:h-2/3 overflow-y-auto relative">
        <button onClick={handleClose} className="absolute top-2 right-2 text-red-500">✖</button>
        <div className="mt-4">
          <h3 className="text-lg font-bold mb-2">Details for {contact.first_name} {contact.last_name}</h3>

          {/* No client found scenario */}
          {noClientFound && (
            <>
              <p className="text-red-500 py-4">Client was not found in Service Fusion 
                <button className="ml-2 bg-blue-500 text-white text-xs py-1 px-3 rounded hover:bg-blue-600 transition-colors" onClick={onCreateCustomer}>
                  Create New Customer
                </button>
              </p>
            </>
          )}

          {/* Single client found */}
          {clientData.length === 1 && (
            <div className="text-green-500">
              <div>Client was found in Service Fusion</div>
               <div className="flow-root">
                <dl className="-my-3 divide-y divide-gray-100 text-sm">
                  <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
                    <dt className="font-medium text-green-900">Account number</dt>
                    <dd className="text-green-700 sm:col-span-2"> {clientData[0].account_number}</dd>
                  </div>
                  <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
                    <dt className="font-medium text-green-900">Name</dt>
                    <dd className="text-green-700 sm:col-span-2">{clientData[0].customer_name}</dd>
                  </div>
                  <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
                    <dt className="font-medium text-green-900">Phone</dt>
                    <dd className="text-green-700 sm:col-span-2">{clientData[0].contacts[0].phones[0].phone}</dd>
                  </div>
                  <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
                    <dt className="font-medium text-green-900">Email</dt>
                    <dd className="text-green-700 sm:col-span-2">{clientData[0].contacts[0].emails[0].email}</dd>
                  </div>

                </dl>
              </div>
            </div>
          )}

          {/* Multiple clients found */}
          {clientData.length > 1 && (
            <>
              <p className="text-yellow-500">We found multiple clients in Service Fusion. Please select one:</p>
              {clientData.map((client, index) => (
                <div key={index} className="mt-2">
                  <input
                    type="radio"
                    id={`client-${client.account_number}`}
                    name="client"
                    value={client.account_number}
                    onChange={() => onSelectClient(client)}
                  />
                  <label htmlFor={`client-${client.account_number}`}>
                  Account: {client.account_number} - Name: {client.customer_name} - Email: {client.contacts[0].emails[0].email}
                  </label>
                </div>
              ))}
            </>
          )}

          {/* Display CSV data even when a client is found or selected */}
          <h3 className="py-3">Client request info</h3>
          <div className="flow-root">
            <dl className="-my-3 divide-y divide-gray-100 text-sm">
              <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
                <dt className="font-medium text-gray-900">Service</dt>
                <dd className="text-gray-700 sm:col-span-2">{contact.service_name}</dd>
              </div>
              <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
                <dt className="font-medium text-gray-900">Price</dt>
                <dd className="text-gray-700 sm:col-span-2">{contact.Price}</dd>
              </div>
              <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
                <dt className="font-medium text-gray-900">Status</dt>
                <dd className="text-gray-700 sm:col-span-2"> {contact.status}</dd>
              </div>
              <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
                <dt className="font-medium text-gray-900">Start Date</dt>
                <dd className="text-gray-700 sm:col-span-2">{contact.start_date}</dd>
              </div>

              <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
                <dt className="font-medium text-gray-900">Description</dt>
                <dd className="text-gray-700 sm:col-span-2">{contact.description}</dd>
              </div>

              <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
                <dt className="font-medium text-gray-900">Email</dt>
                <dd className="text-gray-700 sm:col-span-2">{contact.emails}</dd>
              </div>

              <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
                <dt className="font-medium text-gray-900">Phone</dt>
                <dd className="text-gray-700 sm:col-span-2">{contact.Accuity_phone}</dd>
              </div>

              <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
                <dt className="font-medium text-gray-900">Address</dt>
                <dd className="text-gray-700 sm:col-span-2">
                  {contact.address}, {contact.postal_code}
                </dd>
              </div>
            </dl>
          </div>
          
        </div>
      </div>
    </div>
  );
};

const Card = ({ contact }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isCreateCustomerFormOpen, setCreateCustomerFormOpen] = useState(false);
  const [clientData, setClientData] = useState([]);  // Store found clients
  const [noClientFound, setNoClientFound] = useState(false);  // Track if no client was found
  const accessToken = localStorage.getItem('access_token');  // Get access token from localStorage

  const handleModalOpen = async () => {
    // Make the API calls to check if the client exists in Service Fusion
    const clients = await checkClientInServiceFusion(contact);
    if (clients.length > 0) {
      setClientData(clients);  // Store the found clients
    } else {
      setNoClientFound(true);  // Set no client found flag
    }
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setNoClientFound(false);
  };

  const handleCreateCustomer = () => {
    setModalOpen(false);
    setCreateCustomerFormOpen(true);
  };

  const handleCreateCustomerFormClose = () => {
    setCreateCustomerFormOpen(false);
  };

  const handleNewCustomerSubmit = (newCustomer) => {
    console.log('New customer created:', newCustomer);
    setCreateCustomerFormOpen(false);
    // Add any further logic to submit the new customer to the API
  };

  const handleSelectClient = (client) => {
    setClientData([client]);  // Only keep the selected client
  };

  // Function to check if the client exists in Service Fusion by making 2 API calls (email and phone)
  const checkClientInServiceFusion = async (contact) => {
    const headers = {
      "Accept": "application/json",
      "Authorization": `Bearer ${accessToken}`,
    };

    const emailUrl = `${config.servicefusion.API.customersUrl}?filters[email]=${contact.emails}&expand=contacts.emails,contacts.phones,locations`;
    const phoneUrl = `${config.servicefusion.API.customersUrl}?filters[phone]=${contact.Accuity_phone}&expand=contacts.emails,contacts.phones,locations`;

    try {
      const [emailData, phoneData] = await Promise.all([
        fetchWithAuth(emailUrl, { headers }),
        fetchWithAuth(phoneUrl, { headers }),
      ]);

      // Combine results from both email and phone search, ensuring uniqueness based on account_number
      const combinedData = [...emailData.items, ...phoneData.items];
      const uniqueClients = Array.from(new Set(combinedData.map(client => client.account_number)))
        .map(account_number => combinedData.find(client => client.account_number === account_number));

      return uniqueClients;
    } catch (error) {
      console.error('Error checking client in Service Fusion:', error);
      return [];
    }
  };

  return (
    <div
      className={`"flex justify-between items-center p-2 border-b border-gray-300" ${contact.processed === 'X' ? 'bg-gray-200' : ''}`}
    >
      <div className="truncate text-sm">
        <strong>{contact.first_name} {contact.last_name}</strong> - {contact.address}, {contact.postal_code} - {contact.Accuity_phone} - {contact.emails}
      </div>
      {contact.processed === 'X' ? '' : <button
        onClick={handleModalOpen}  
        className="bg-blue-500 text-white text-xs py-1 px-3 rounded hover:bg-blue-600 transition-colors"
      >Validate Customer
      </button>}
      

      {/* Modal for showing client details */}
      <Modal
        show={isModalOpen}
        handleClose={handleModalClose}
        contact={contact}
        clientData={clientData}
        noClientFound={noClientFound}
        onCreateCustomer={handleCreateCustomer}
        onSelectClient={handleSelectClient}
      />

      {/* Create New Customer Form */}
      <CreateCustomer
        show={isCreateCustomerFormOpen}
        handleClose={handleCreateCustomerFormClose}
        defaultData={contact}  
        onSubmit={handleNewCustomerSubmit}
      />

    </div>
  );
};

export default Card;
