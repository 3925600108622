import React, { useState, useEffect } from 'react';
import '../index.css';
import Papa from 'papaparse';
import Card from './Card';
import config from '../config';
import { redirectToServiceFusionOAuth } from '../utils/api';  
import logo from '../images/j3.jpg';  

const App = () => {
  const [user, setUser] = useState(null);  // State to store the user
  const [csvData, setCsvData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(''); // State to store error messages

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');

    // Verify the state matches the one stored in localStorage
    const storedState = localStorage.getItem('state');

    if (code && state) {
      if (storedState === state) {
        // If the state matches, exchange the code for an access token
        exchangeCodeForToken(code);
      } else {
        // If the state doesn't match, display an error message
        setErrorMessage('A little bit of shit just hit the fan. Please try logging in again.');
      }
    } else {
      const accessToken = localStorage.getItem('access_token');
      if (accessToken) {
        setUser({ accessToken });
        fetchData();  // Start fetching CSV data
      } else {
        redirectToServiceFusionOAuth();
      }
    }
  }, []);

  // Function to exchange the authorization code for an access token
  const exchangeCodeForToken = async (code) => {
    const clientSecret = config.servicefusion.API.clientSecret;
    const clientId = config.servicefusion.API.clientId;

    const response = await fetch(`${config.servicefusion.API.accessTokenUrl}`, {
      method: 'POST',
      body: JSON.stringify({
        grant_type: 'authorization_code',
        client_id: clientId,
        client_secret: clientSecret,
        code: code,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();

    if (response.ok && data.access_token) {
      // Store the access token in localStorage
      localStorage.setItem('access_token', data.access_token);
      setUser({ accessToken: data.access_token });
      // Remove the `code` and `state` parameters from the URL after successful login
      removeUrlParameters();
      fetchData();  // Fetch the data now that we're authenticated
    } else {
      // If the token is missing, clear localStorage and start the login process again
      localStorage.removeItem('state');
      setErrorMessage('Error retrieving access token. Please try logging in again.');
    }
  };

  // Function to remove `code` and `state` parameters from the URL
  const removeUrlParameters = () => {
    const url = new URL(window.location);
    url.searchParams.delete('code');
    url.searchParams.delete('state');
    window.history.replaceState({}, document.title, url.toString());
  };

  // Function to fetch data from the CSV
  const fetchData = () => {
    const csvUrl = config.googleSheet;
    Papa.parse(csvUrl, {
      download: true,
      header: true,
      complete: (result) => {
        const cleanedData = result.data.filter((row) => {
          return Object.values(row).some(field => field && field.trim() !== '');
        });
        setCsvData(cleanedData.reverse());
      }
    });
  };

  // If there's an error with state mismatch or token retrieval, show an error message and provide a login button
  if (errorMessage) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="bg-white p-8 rounded-lg shadow-md text-center">
          <h2 className="text-2xl font-bold mb-4">Error</h2>
          <p className="text-red-500">{errorMessage}</p>
          <button 
            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
            onClick={redirectToServiceFusionOAuth}
          >
            Log in Again
          </button>
        </div>
      </div>
    );
  }

  // If user is not authenticated, show nothing until they log in
  if (!user) {
    return null;
  }
  const signOut = ()=>{
    localStorage.removeItem('access_token');
    localStorage.removeItem('state');
    redirectToServiceFusionOAuth();  // Redirect to the login screen
    return null;
  }
  // Show the main app once the user is logged in and authenticated
  return (
  <div className="flex">
    <div className="w-1/12 h-screen bg-white p-2">
      <img src={logo} alt="Logo" className="w-80 mx-auto" />
      <button
        onClick={signOut}
        className="bg-red-500 text-white text-sm mt-8 py-1 px-2 rounded block mx-auto"
      >
        Log Out
      </button>
    </div>
    <div className="w-11/12 p-4 bg-white">
      <h1 className="text-2xl font-bold mb-6">Customer Dashboard</h1>
      <div className="grid grid-cols-1">
        {csvData.filter(contact => contact.first_name !== "" && contact.last_name !== "").map((contact, index) => (
          <Card key={index} contact={contact} />
        ))}
      </div>
    </div>
  </div>
  );
};

export default App;
