import React, { useState } from 'react';
import { fetchWithAuth } from '../utils/api';  // Import the utility function
import config from '../config';  // Make sure config is imported

const CreateCustomer = ({ show, handleClose, defaultData, onSubmit }) => {
  // Set initial form values from defaultData (CSV data)
  const [name, setName] = useState(defaultData.first_name + ' ' + defaultData.last_name);
  const [email, setEmail] = useState(defaultData.emails);
  const [phone, setPhone] = useState(defaultData.Accuity_phone);
  const [address, setAddress] = useState(defaultData.address);
  const [postalCode, setPostalCode] = useState(defaultData.postal_code);
  const [hasGasFireplace, setHasGasFireplace] = useState(defaultData.has_gasfireplace === "yes");

  // Function to format the phone number
  const formatPhone = (inputPhone) => {
    inputPhone = inputPhone + '';
    let phoneObj = {};
    if (inputPhone.indexOf('x') > -1) {
      const explode = inputPhone.split('x');
      phoneObj.phone = explode[0];
      phoneObj.ext = explode[1];
    } else {
      phoneObj.phone = inputPhone;
    }
    let cleaned = phoneObj.phone.replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      phoneObj.phone = match[1] + '-' + match[2] + '-' + match[3];
    } else {
      delete phoneObj.phone;
    }
    return phoneObj;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const phoneObj = formatPhone(phone);
    const cust_fsa = postalCode.substring(0, 3).toUpperCase();

    // Ensure postal code exists in config (this is a placeholder logic for demo purposes)
    if (!(cust_fsa in config.fsa) || (cust_fsa in config.fsa && !('city' in config.fsa[cust_fsa]))) {
      alert("Postal code not found");
      return;
    }

    const city = config.fsa[cust_fsa].city;
    const addressSplit = address.split(" ");
    let street_1 = addressSplit[0];
    addressSplit.shift();
    let street_2 = addressSplit.length > 0 ? addressSplit.join(" ") : "";

    // Prepare the customer data for API request
    const newData = {
      customer_name: name,
      private_notes: "created with Jaws III",
      referral_source: defaultData.source,  // From CSV
      contacts: [
        {
          fname: defaultData.first_name,
          lname: defaultData.last_name,
          is_primary: true,
          phones: [phoneObj],
          emails: [{ email: email }]
        }
      ],
      locations: [
        {
          street_1: street_1,
          street_2: street_2,
          city: city,
          state_prov: "ON",
          postal_code: postalCode,
          country: "Canada",
          is_primary: true,
          customer_contact: name
        }
      ],
      custom_fields: [
        {
          name: "Has gas fireplace",
          value: hasGasFireplace
        }
      ]
    };

    // Retrieve the access token from localStorage
    const accessToken = localStorage.getItem('access_token');

    // Perform the API call to create a new customer using fetchWithAuth
    const responseData = await fetchWithAuth(config.servicefusion.API.customersUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Authorization header with access token
      },
      body: JSON.stringify(newData)
    });

    if (responseData) {
      alert('Customer created successfully!');
      onSubmit(responseData);  // Pass the created customer data back to the parent component
    } else {
      alert('Error creating customer. Please try again.');
    }
  };

  return (
    <div
      id="modal-overlay"
      className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center ${show ? 'block' : 'hidden'}`}
      onClick={(e) => {
        if (e.target.id === "modal-overlay") {
          handleClose();
        }
      }}
    >
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full relative">
        <button onClick={handleClose} className="absolute top-2 right-2 text-red-500">✖</button>
        <form onSubmit={handleFormSubmit}>
          <div className="mt-4">
            <h3 className="text-lg font-bold mb-2">Create New Customer</h3>

            <div className="mb-4">
              <label className="block mb-1">Name</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="border p-2 w-full rounded"
                placeholder="Enter customer name"
              />
            </div>

            <div className="mb-4">
              <label className="block mb-1">Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="border p-2 w-full rounded"
                placeholder="Enter customer email"
              />
            </div>

            <div className="mb-4">
              <label className="block mb-1">Phone</label>
              <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="border p-2 w-full rounded"
                placeholder="Enter customer phone number"
              />
            </div>

            <div className="mb-4">
              <label className="block mb-1">Address</label>
              <input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className="border p-2 w-full rounded"
                placeholder="Enter customer address"
              />
            </div>

            <div className="mb-4">
              <label className="block mb-1">Postal Code</label>
              <input
                type="text"
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                className="border p-2 w-full rounded"
                placeholder="Enter postal code"
              />
            </div>

            <div className="mb-4">
              <label className="block mb-1">Has Gas Fireplace</label>
              <input
                type="checkbox"
                checked={hasGasFireplace}
                onChange={(e) => setHasGasFireplace(e.target.checked)}
                className="mr-2"
              /> Yes
            </div>

            <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded mt-4">Create Customer</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateCustomer;
