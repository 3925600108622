// auth on jaws-3.web.app
            // 'clientId':'rhMkY0SzE38PxYDlNa',
            // 'clientSecret':'MxHNUlNLms-Amti5hiRqtX8Crsu4owoH'
//
const config = {
    firebase:{
        apiKey: "AIzaSyAw-5rkurPY-s77Ec50XUq4geUrZxhyD80",
        authDomain: "jaws3-35eff.firebaseapp.com",
        projectId: "jaws3-35eff",
        storageBucket: "jaws3-35eff.appspot.com",
        messagingSenderId: "337734699993",
        appId: "1:337734699993:web:aa7775104a11fe1c62a7c8"
    },
    googleSheet:'https://docs.google.com/spreadsheets/d/e/2PACX-1vR2A9yB_Je4LaL5n5xpBqZHgcxTpiOM0ymbqoyHucjWU_mUNkFSfKV9rON1meIB1TcnOozE6YpxgIDI/pub?gid=1519381333&single=true&output=csv',
    servicefusion : {
        API : {
            'oauthUrl':'https://api.servicefusion.com/oauth/authorize',
            'accessTokenUrl':'https://jaws3.3615toronto.ca/proxy.php',
            'customersUrl':'https://api.servicefusion.com/v1/customers',
            'jobsUrl':'https://api.servicefusion.com/v1/jobs',
            'clientId':'K6mR5Sq3-U6rEmRYdZ',
            'clientSecret':'XHlToTgqw_dmLwfYe0gHamv2duvq4sew'
        },
        dataStructure : [
            { 
            'fieldName':'firstName',
            'columnIndex':0,
            'includeInRequest':true
            },
            { 
            'fieldName':'lastName',
            'columnIndex':1,
            'includeInRequest':true
            },
            { 
            'fieldName':'email',
            'columnIndex':9,
            'includeInRequest':true
            },
            { 
            'fieldName':'customer_name',
            'columnIndex':26,
            'includeInRequest':true
            },
            { 
            'fieldName':'id',
            'columnIndex':27,
            'includeInRequest':false
            },
            { 
            'fieldName':'referral_source',
            'columnIndex':17,
            'includeInRequest':true
            },
            { 
            'fieldName':'raw_address',
            'columnIndex':3,
            'includeInRequest':true
            },
            { 
            'fieldName':'postal_code',
            'columnIndex':7,
            'includeInRequest':true
            },
            { 
            'fieldName':'phone',
            'columnIndex':8,
            'includeInRequest':true
            },
            { 
            'fieldName':'date',
            'columnIndex':10,
            'includeInRequest':true,
            'isDate':true
            },
            { 
            'fieldName':'hour',
            'columnIndex':11,
            'includeInRequest':true,
            'isTime':true
            },
            { 
            'fieldName':'price',
            'columnIndex':13,
            'includeInRequest':true
            },
            { 
            'fieldName':'has_gasfireplace',
            'columnIndex':2,
            'includeInRequest':true
            },
            {
            'fieldName':'description',
            'columnIndex':16,
            'includeInRequest':true
            },
            {
            'fieldName':'job_status',
            'columnIndex':14,
            'includeInRequest':true
            },
            {
            'fieldName':'customer_name',
            'columnIndex':26,
            'includeInRequest':true
            },
            {
            'fieldName':'customer_id',
            'columnIndex':27,
            'includeInRequest':true
            },
            {
            'fieldName':'customer_account_number',
            'columnIndex':28,
            'includeInRequest':true
            },
            {
            'fieldName':'scraper',
            'columnIndex':18,
            'includeInRequest':true
            },
            {
            'fieldName':'express',
            'columnIndex':22,
            'includeInRequest':true
            },
            {
            'fieldName':'service_name',
            'columnIndex':12,
            'includeInRequest':true
            },
            {
            'fieldName':'service_price',
            'columnIndex':13,
            'includeInRequest':true
            }
        ]
        }
    ,
    fsa : {
        "K0A":{"city":"Ottawa", "Michael Laclef":""},
        "K0B":{"city":"Rockland", "Michael Laclef":""},
        "K0C":{"city":"Cornwall", "tech":""},
        "K0E":{"city":"Brockville", "tech":""},
        "K0G":{"city":"Kemptville", "Michael Laclef":""},
        "K0H":{"city":"Kingston", "tech":""},
        "K0J":{"city":"Ottawa", "Michael Laclef":""},
        "K0K":{"city":"Belleville", "tech":""},
        "K0L":{"city":"Peterborough", "tech":""},
        "K0M":{"city":"Lindsay", "tech":""},
        "K1A":{"city":"Nepean", "Michael Laclef":""},
        "K1B":{"city":"Ottawa", "Michael Laclef":""},
        "K1C":{"city":"Gloucester", "tech":""},
        "K1E":{"city":"Gloucester", "tech":""},
        "K1G":{"city":"Ottawa", "Michael Laclef":""},
        "K1H":{"city":"Ottawa", "Michael Laclef":""},
        "K1J":{"city":"Ottawa", "Michael Laclef":""},
        "K1K":{"city":"Vanier", "tech":""},
        "K1L":{"city":"Vanier", "tech":""},
        "K1M":{"city":"Ottawa", "Michael Laclef":""},
        "K1N":{"city":"Ottawa", "Michael Laclef":""},
        "K1P":{"city":"Ottawa", "Michael Laclef":""},
        "K1R":{"city":"Ottawa", "Michael Laclef":""},
        "K1S":{"city":"Ottawa", "Michael Laclef":""},
        "K1T":{"city":"Ottawa", "Michael Laclef":""},
        "K1V":{"city":"Ottawa", "Michael Laclef":""},
        "K1W":{"city":"Gloucester", "tech":""},
        "K1X":{"city":"Gloucester", "tech":""},
        "K1Y":{"city":"Ottawa", "Michael Laclef":""},
        "K1Z":{"city":"Ottawa", "Michael Laclef":""},
        "K2A":{"city":"Ottawa", "Michael Laclef":""},
        "K2B":{"city":"Nepean", "tech":""},
        "K2C":{"city":"Nepean", "tech":""},
        "K2E":{"city":"Nepean", "tech":""},
        "K2G":{"city":"Nepean", "tech":""},
        "K2H":{"city":"Nepean", "tech":""},
        "K2J":{"city":"Nepean", "tech":""},
        "K2K":{"city":"Nepean", "tech":""},
        "K2L":{"city":"Nepean", "tech":""},
        "K2M":{"city":"Kanata", "Michael Laclef":""},
        "K2P":{"city":"Ottawa", "Michael Laclef":""},
        "K2R":{"city":"Nepean", "tech":""},
        "K2S":{"city":"Ottawa", "Michael Laclef":""},
        "K2T":{"city":"Kanata", "Michael Laclef":""},
        "K2V":{"city":"Kanata", "Michael Laclef":""},
        "K2W":{"city":"Kanata", "Michael Laclef":""},
        "K4A":{"city":"Gloucester", "Michael Laclef":""},
        "K4B":{"city":"Gloucester", "Michael Laclef":""},
        "K4C":{"city":"Cumberland", "Michael Laclef":""},
        "K4K":{"city":"Clarence-Rockland", "Michael Laclef":""},
        "K4M":{"city":"Ottawa", "Michael Laclef":""},
        "K4P":{"city":"Ottawa", "Michael Laclef":""},
        "K4R":{"city":"Russell", "tech":""},
        "K6A":{"city":"Hawkesbury", "tech":""},
        "K6H":{"city":"Cornwall", "tech":""},
        "K6J":{"city":"Cornwall", "tech":""},
        "K6K":{"city":"Cornwall", "tech":""},
        "K6T":{"city":"Brockville", "tech":""},
        "K6V":{"city":"Brockville", "tech":""},
        "K7A":{"city":"Smiths Falls", "tech":""},
        "K7C":{"city":"Carleton Place", "tech":""},
        "K7G":{"city":"Gananoque", "tech":""},
        "K7H":{"city":"Perth", "tech":""},
        "K7K":{"city":"Kingston", "tech":""},
        "K7L":{"city":"Kingston", "tech":""},
        "K7M":{"city":"Kingston", "tech":""},
        "K7N":{"city":"Kingston", "tech":""},
        "K7P":{"city":"Kingston", "tech":""},
        "K7R":{"city":"Napanee", "tech":""},
        "K7S":{"city":"Arnprior", "tech":""},
        "K7V":{"city":"Renfrew", "tech":""},
        "K8A":{"city":"Pembroke", "tech":""},
        "K8B":{"city":"Pembroke", "tech":""},
        "K8H":{"city":"Petawawa", "tech":""},
        "K8N":{"city":"Belleville", "tech":""},
        "K8P":{"city":"Belleville", "tech":""},
        "K8R":{"city":"Belleville", "tech":""},
        "K8V":{"city":"Quinte West", "tech":""},
        "K9A":{"city":"Cobourg", "tech":""},
        "K9H":{"city":"Peterborough", "tech":""},
        "K9J":{"city":"Peterborough", "tech":""},
        "K9K":{"city":"Peterborough", "tech":""},
        "K9L":{"city":"Peterborough", "tech":""},
        "K9V":{"city":"Lindsay", "tech":""},
        "L0A":{"city":"Millbrook", "tech":""},
        "L0B":{"city":"Whitby", "tech":"Yonathan Seifu"},
        "L0C":{"city":"Whitby", "tech":"Yonathan Seifu"},
        "L0E":{"city":"Keswick", "tech":""},
        "L0G":{"city":"Tottenham", "tech":"Pedro Gonzalez"},
        "L0H":{"city":"Gormley", "tech":""},
        "L0J":{"city":"Kleinburg", "tech":"Pedro Gonzalez"},
        "L0K":{"city":"Orillia", "tech":"Pedro Gonzalez"},
        "L0L":{"city":"Barrie", "tech":"Pedro Gonzalez"},
        "L0M":{"city":"Barrie", "tech":"Pedro Gonzalez"},
        "L0N":{"city":"Brampton", "tech":"Krystian Smith"},
        "L0P":{"city":"Mississauga", "tech":"Krystian Smith"},
        "L0R":{"city":"Hamilton", "tech":"Krystian Smith"},
        "L0S":{"city":"Ridgeville", "tech":""},
        "L1A":{"city":"Port Hope", "tech":""},
        "L1B":{"city":"Newcastle", "tech":"Yonathan Seifu"},
        "L1C":{"city":"Bowmanville", "tech":"Yonathan Seifu"},
        "L1E":{"city":"Bowmanville", "tech":"Yonathan Seifu"},
        "L1G":{"city":"Oshawa", "tech":"Yonathan Seifu"},
        "L1H":{"city":"Oshawa", "tech":"Yonathan Seifu"},
        "L1J":{"city":"Oshawa", "tech":"Yonathan Seifu"},
        "L1K":{"city":"Oshawa", "tech":"Yonathan Seifu"},
        "L1L":{"city":"Oshawa", "tech":"Yonathan Seifu"},
        "L1M":{"city":"Whitby", "tech":"Yonathan Seifu"},
        "L1N":{"city":"Whitby", "tech":"Yonathan Seifu"},
        "L1P":{"city":"Whitby", "tech":"Yonathan Seifu"},
        "L1R":{"city":"Whitby", "tech":"Yonathan Seifu"},
        "L1S":{"city":"Ajax", "tech":"Yonathan Seifu"},
        "L1T":{"city":"Ajax", "tech":"Yonathan Seifu"},
        "L1V":{"city":"Pickering", "tech":"Yonathan Seifu"},
        "L1W":{"city":"Pickering", "tech":"Yonathan Seifu"},
        "L1X":{"city":"Pickering", "tech":"Yonathan Seifu"},
        "L1Y":{"city":"Pickering", "tech":"Yonathan Seifu"},
        "L1Z":{"city":"Ajax", "tech":"Yonathan Seifu"},
        "L2A":{"city":"Fort Erie", "tech":""},
        "L2E":{"city":"Niagara Falls", "tech":""},
        "L2G":{"city":"Niagara Falls", "tech":""},
        "L2H":{"city":"Niagara Falls", "tech":""},
        "L2J":{"city":"Niagara Falls", "tech":""},
        "L2M":{"city":"St. Catharines", "tech":""},
        "L2N":{"city":"St. Catharines", "tech":""},
        "L2P":{"city":"St. Catharines", "tech":""},
        "L2R":{"city":"St. Catharines", "tech":""},
        "L2S":{"city":"St. Catharines", "tech":""},
        "L2T":{"city":"St. Catharines", "tech":""},
        "L2V":{"city":"Thorold", "tech":""},
        "L2W":{"city":"St. Catharines", "tech":""},
        "L3B":{"city":"Welland", "tech":""},
        "L3C":{"city":"Welland", "tech":""},
        "L3K":{"city":"Port Colborne", "tech":""},
        "L3M":{"city":"Grimsby", "tech":"Krystian Smith"},
        "L3P":{"city":"Markham", "tech":"Pedro Gonzalez"},
        "L3R":{"city":"Markham", "tech":"Pedro Gonzalez"},
        "L3S":{"city":"Markham", "tech":"Pedro Gonzalez"},
        "L3T":{"city":"Markham", "tech":"Pedro Gonzalez"},
        "L3V":{"city":"Rama", "tech":"Pedro Gonzalez"},
        "L3X":{"city":"Newmarket", "tech":"Pedro Gonzalez"},
        "L3Y":{"city":"Newmarket", "tech":"Pedro Gonzalez"},
        "L3Z":{"city":"Bradford", "tech":"Pedro Gonzalez"},
        "L4A":{"city":"Whitchurch-Stouffville", "tech":"Pedro Gonzalez"},
        "L4B":{"city":"Richmond Hill", "tech":"Pedro Gonzalez"},
        "L4C":{"city":"Richmond Hill", "tech":"Pedro Gonzalez"},
        "L4E":{"city":"Gormley", "tech":"Pedro Gonzalez"},
        "L4G":{"city":"Aurora", "tech":"Pedro Gonzalez"},
        "L4H":{"city":"Vaughan", "tech":"Pedro Gonzalez"},
        "L4J":{"city":"Vaughan", "tech":"Shane Zee"},
        "L4K":{"city":"Vaughan", "tech":"Pedro Gonzalez"},
        "L4L":{"city":"Vaughan", "tech":"Pedro Gonzalez"},
        "L4M":{"city":"Barrie", "tech":"Pedro Gonzalez"},
        "L4N":{"city":"Barrie", "tech":"Pedro Gonzalez"},
        "L4P":{"city":"Georgina", "tech":"Pedro Gonzalez"},
        "L4R":{"city":"Midland", "tech":""},
        "L4S":{"city":"Richmond Hill", "tech":"Pedro Gonzalez"},
        "L4T":{"city":"Mississauga", "tech":"Krystian Smith"},
        "L4V":{"city":"Mississauga", "tech":"Krystian Smith"},
        "L4W":{"city":"Mississauga", "tech":"Krystian Smith"},
        "L4X":{"city":"Mississauga", "tech":"Krystian Smith"},
        "L4Y":{"city":"Mississauga", "tech":"Krystian Smith"},
        "L4Z":{"city":"Mississauga", "tech":"Krystian Smith"},
        "L5A":{"city":"Mississauga", "tech":"Krystian Smith"},
        "L5B":{"city":"Mississauga", "tech":"Krystian Smith"},
        "L5C":{"city":"Mississauga", "tech":"Krystian Smith"},
        "L5E":{"city":"Mississauga", "tech":"Krystian Smith"},
        "L5G":{"city":"Mississauga", "tech":"Krystian Smith"},
        "L5H":{"city":"Mississauga", "tech":"Krystian Smith"},
        "L5J":{"city":"Mississauga", "tech":"Krystian Smith"},
        "L5K":{"city":"Mississauga", "tech":"Krystian Smith"},
        "L5L":{"city":"Mississauga", "tech":"Krystian Smith"},
        "L5M":{"city":"Mississauga", "tech":"Krystian Smith"},
        "L5N":{"city":"Mississauga", "tech":"Krystian Smith"},
        "L5P":{"city":"Mississauga", "tech":"Krystian Smith"},
        "L5R":{"city":"Mississauga", "tech":"Krystian Smith"},
        "L5S":{"city":"Mississauga", "tech":"Krystian Smith"},
        "L5T":{"city":"Mississauga", "tech":"Krystian Smith"},
        "L5V":{"city":"Mississauga", "tech":"Krystian Smith"},
        "L5W":{"city":"Mississauga", "tech":"Krystian Smith"},
        "L6A":{"city":"Vaughan", "tech":"Pedro Gonzalez"},
        "L6B":{"city":"Markham", "tech":"Pedro Gonzalez"},
        "L6C":{"city":"Markham", "tech":"Pedro Gonzalez"},
        "L6E":{"city":"Markham", "tech":"Pedro Gonzalez"},
        "L6G":{"city":"Markham", "tech":"Pedro Gonzalez"},
        "L6H":{"city":"Oakville", "tech":"Krystian Smith"},
        "L6J":{"city":"Oakville", "tech":"Krystian Smith"},
        "L6K":{"city":"Oakville", "tech":"Krystian Smith"},
        "L6L":{"city":"Oakville", "tech":"Krystian Smith"},
        "L6M":{"city":"Oakville", "tech":"Krystian Smith"},
        "L6P":{"city":"Brampton", "tech":"Krystian Smith"},
        "L6R":{"city":"Brampton", "tech":"Krystian Smith"},
        "L6S":{"city":"Brampton", "tech":"Krystian Smith"},
        "L6T":{"city":"Brampton", "tech":"Krystian Smith"},
        "L6V":{"city":"Brampton", "tech":"Krystian Smith"},
        "L6W":{"city":"Brampton", "tech":"Krystian Smith"},
        "L6X":{"city":"Brampton", "tech":"Krystian Smith"},
        "L6Y":{"city":"Brampton", "tech":"Krystian Smith"},
        "L6Z":{"city":"Brampton", "tech":"Krystian Smith"},
        "L7A":{"city":"Brampton", "tech":"Krystian Smith"},
        "L7B":{"city":"King City", "tech":"Pedro Gonzalez"},
        "L7C":{"city":"Caledon", "tech":"Pedro Gonzalez"},
        "L7E":{"city":"Palgrave", "tech":"Krystian Smith"},
        "L7G":{"city":"Georgetown", "tech":"Krystian Smith"},
        "L7J":{"city":"Acton", "tech":"Krystian Smith"},
        "L7K":{"city":"Caledon", "tech":"Pedro Gonzalez"},
        "L7L":{"city":"Burlington", "tech":"Krystian Smith"},
        "L7M":{"city":"Burlington", "tech":"Krystian Smith"},
        "L7N":{"city":"Burlington", "tech":"Krystian Smith"},
        "L7P":{"city":"Burlington", "tech":"Krystian Smith"},
        "L7R":{"city":"Burlington", "tech":"Krystian Smith"},
        "L7S":{"city":"Burlington", "tech":"Krystian Smith"},
        "L7T":{"city":"Burlington", "tech":"Krystian Smith"},
        "L8B":{"city":"Hamilton", "tech":"Krystian Smith"},
        "L8E":{"city":"Hamilton", "tech":"Krystian Smith"},
        "L8G":{"city":"Hamilton", "tech":"Krystian Smith"},
        "L8H":{"city":"Hamilton", "tech":"Krystian Smith"},
        "L8J":{"city":"Hamilton", "tech":"Krystian Smith"},
        "L8K":{"city":"Hamilton", "tech":"Krystian Smith"},
        "L8L":{"city":"Hamilton", "tech":"Krystian Smith"},
        "L8M":{"city":"Hamilton", "tech":"Krystian Smith"},
        "L8N":{"city":"Hamilton", "tech":"Krystian Smith"},
        "L8P":{"city":"Hamilton", "tech":"Krystian Smith"},
        "L8R":{"city":"Hamilton", "tech":"Krystian Smith"},
        "L8S":{"city":"Hamilton", "tech":"Krystian Smith"},
        "L8T":{"city":"Hamilton", "tech":"Krystian Smith"},
        "L8V":{"city":"Hamilton", "tech":"Krystian Smith"},
        "L8W":{"city":"Hamilton", "tech":"Krystian Smith"},
        "L9A":{"city":"Hamilton", "tech":"Krystian Smith"},
        "L9B":{"city":"Hamilton", "tech":"Krystian Smith"},
        "L9C":{"city":"Hamilton", "tech":"Krystian Smith"},
        "L9E":{"city":"Milton", "tech":"Krystian Smith"},
        "L9G":{"city":"Hamilton", "tech":"Krystian Smith"},
        "L9H":{"city":"Hamilton", "tech":"Krystian Smith"},
        "L9J":{"city":"Barrie", "tech":"Pedro Gonzalez"},
        "L9K":{"city":"Hamilton", "tech":"Krystian Smith"},
        "L9L":{"city":"Port Perry", "tech":""},
        "L9M":{"city":"Penetanguishene", "tech":""},
        "L9N":{"city":"East Gwillimbury", "tech":"Pedro Gonzalez"},
        "L9P":{"city":"Uxbridge", "tech":"Pedro Gonzalez"},
        "L9R":{"city":"New Tecumseth", "tech":"Pedro Gonzalez"},
        "L9S":{"city":"Innisfil", "tech":"Pedro Gonzalez"},
        "L9T":{"city":"Milton", "tech":"Krystian Smith"},
        "L9V":{"city":"Orangeville", "tech":""},
        "L9W":{"city":"Orangeville", "tech":""},
        "L9X":{"city":"Minesing", "tech":""},
        "L9Y":{"city":"The Blue Mountains", "tech":"Pedro Gonzalez"},
        "L9Z":{"city":"Wasaga Beach", "tech":"Pedro Gonzalez"},
        "M1B":{"city":"Scarborough", "tech":"Yonathan Seifu"},
        "M1C":{"city":"Scarborough", "tech":"Yonathan Seifu"},
        "M1E":{"city":"Scarborough", "tech":"Yonathan Seifu"},
        "M1G":{"city":"Scarborough", "tech":"Yonathan Seifu"},
        "M1H":{"city":"Scarborough", "tech":"Yonathan Seifu"},
        "M1J":{"city":"Scarborough", "tech":"Yonathan Seifu"},
        "M1K":{"city":"Scarborough", "tech":"Yonathan Seifu"},
        "M1L":{"city":"North York", "tech":"Aholding Spot"},
        "M1M":{"city":"Scarborough", "tech":"Yonathan Seifu"},
        "M1N":{"city":"Scarborough", "tech":"Yonathan Seifu"},
        "M1P":{"city":"Scarborough", "tech":"Yonathan Seifu"},
        "M1R":{"city":"North York", "tech":"Aholding Spot"},
        "M1S":{"city":"Scarborough", "tech":"Yonathan Seifu"},
        "M1T":{"city":"Scarborough", "tech":"Yonathan Seifu"},
        "M1V":{"city":"Scarborough", "tech":"Yonathan Seifu"},
        "M1W":{"city":"Scarborough", "tech":"Yonathan Seifu"},
        "M1X":{"city":"Scarborough", "tech":"Yonathan Seifu"},
        "M2H":{"city":"North York", "tech":"Aholding Spot"},
        "M2J":{"city":"North York", "tech":"Aholding Spot"},
        "M2K":{"city":"North York", "tech":"Aholding Spot"},
        "M2L":{"city":"North York", "tech":"Aholding Spot"},
        "M2M":{"city":"North York", "tech":"Aholding Spot"},
        "M2N":{"city":"North York", "tech":"Aholding Spot"},
        "M2P":{"city":"North York", "tech":"Aholding Spot"},
        "M2R":{"city":"North York", "tech":"Aholding Spot"},
        "M3A":{"city":"North York", "tech":"Aholding Spot"},
        "M3B":{"city":"North York", "tech":"Aholding Spot"},
        "M3C":{"city":"North York", "tech":"Aholding Spot"},
        "M3H":{"city":"North York", "tech":"Aholding Spot"},
        "M3J":{"city":"North York", "tech":"Aholding Spot"},
        "M3K":{"city":"North York", "tech":"Aholding Spot"},
        "M3L":{"city":"North York", "tech":"Aholding Spot"},
        "M3M":{"city":"North York", "tech":"Aholding Spot"},
        "M3N":{"city":"North York", "tech":"Aholding Spot"},
        "M4A":{"city":"North York", "tech":"Aholding Spot"},
        "M4B":{"city":"Toronto", "tech":"Aholding Spot"},
        "M4C":{"city":"Toronto", "tech":"Aholding Spot"},
        "M4E":{"city":"Toronto", "tech":"Aholding Spot"},
        "M4G":{"city":"North York", "tech":"Aholding Spot"},
        "M4H":{"city":"Toronto", "tech":"Aholding Spot"},
        "M4J":{"city":"Toronto", "tech":"Aholding Spot"},
        "M4K":{"city":"Toronto", "tech":"Aholding Spot"},
        "M4L":{"city":"Old Toronto", "tech":"Aholding Spot"},
        "M4M":{"city":"Toronto", "tech":"Aholding Spot"},
        "M4N":{"city":"Toronto", "tech":"Aholding Spot"},
        "M4P":{"city":"North York", "tech":"Aholding Spot"},
        "M4R":{"city":"North York", "tech":"Aholding Spot"},
        "M4S":{"city":"Old Toronto", "tech":"Aholding Spot"},
        "M4T":{"city":"Old Toronto", "tech":"Aholding Spot"},
        "M4V":{"city":"Old Toronto", "tech":"Aholding Spot"},
        "M4W":{"city":"Old Toronto", "tech":"Aholding Spot"},
        "M4X":{"city":"Old Toronto", "tech":"Aholding Spot"},
        "M4Y":{"city":"Old Toronto", "tech":"Aholding Spot"},
        "M5A":{"city":"Old Toronto", "tech":"Aholding Spot"},
        "M5B":{"city":"Old Toronto", "tech":"Aholding Spot"},
        "M5C":{"city":"Old Toronto", "tech":"Aholding Spot"},
        "M5E":{"city":"Old Toronto", "tech":"Aholding Spot"},
        "M5G":{"city":"Old Toronto", "tech":"Aholding Spot"},
        "M5H":{"city":"Old Toronto", "tech":"Aholding Spot"},
        "M5J":{"city":"Toronto", "tech":"Aholding Spot"},
        "M5K":{"city":"Old Toronto", "tech":"Aholding Spot"},
        "M5L":{"city":"Old Toronto", "tech":"Aholding Spot"},
        "M5M":{"city":"Toronto", "tech":"Aholding Spot"},
        "M5N":{"city":"Old Toronto", "tech":"Aholding Spot"},
        "M5P":{"city":"York", "tech":"Aholding Spot"},
        "M5R":{"city":"Old Toronto", "tech":"Aholding Spot"},
        "M5S":{"city":"Old Toronto", "tech":"Aholding Spot"},
        "M5T":{"city":"Old Toronto", "tech":"Aholding Spot"},
        "M5V":{"city":"Toronto", "tech":"Aholding Spot"},
        "M5W":{"city":"Old Toronto", "tech":"Aholding Spot"},
        "M5X":{"city":"Old Toronto", "tech":"Aholding Spot"},
        "M6A":{"city":"North York", "tech":"Aholding Spot"},
        "M6B":{"city":"Toronto", "tech":"Aholding Spot"},
        "M6C":{"city":"Toronto", "tech":"Aholding Spot"},
        "M6E":{"city":"Toronto", "tech":"Aholding Spot"},
        "M6G":{"city":"Old Toronto", "tech":"Aholding Spot"},
        "M6H":{"city":"Old Toronto", "tech":"Aholding Spot"},
        "M6J":{"city":"Old Toronto", "tech":"Aholding Spot"},
        "M6K":{"city":"Old Toronto", "tech":"Aholding Spot"},
        "M6L":{"city":"North York", "tech":"Aholding Spot"},
        "M6M":{"city":"Toronto", "tech":"Aholding Spot"},
        "M6N":{"city":"Toronto", "tech":"Aholding Spot"},
        "M6P":{"city":"Toronto", "tech":"Aholding Spot"},
        "M6R":{"city":"Old Toronto", "tech":"Aholding Spot"},
        "M6S":{"city":"Toronto", "tech":"Aholding Spot"},
        "M7A":{"city":"North York", "tech":"Aholding Spot"},
        "M7R":{"city":"Mississauga", "tech":"Krystian Smith"},
        "M7Y":{"city":"Old Toronto", "tech":"Aholding Spot"},
        "M8V":{"city":"Etobicoke", "tech":"Krystian Smith"},
        "M8W":{"city":"Etobicoke", "tech":"Krystian Smith"},
        "M8X":{"city":"Etobicoke", "tech":"Krystian Smith"},
        "M8Y":{"city":"Etobicoke", "tech":"Krystian Smith"},
        "M8Z":{"city":"Etobicoke", "tech":"Krystian Smith"},
        "M9A":{"city":"Etobicoke", "tech":"Krystian Smith"},
        "M9B":{"city":"Etobicoke", "tech":"Krystian Smith"},
        "M9C":{"city":"Etobicoke", "tech":"Krystian Smith"},
        "M9L":{"city":"North York", "tech":"Aholding Spot"},
        "M9M":{"city":"North York", "tech":"Aholding Spot"},
        "M9N":{"city":"Toronto", "tech":"Aholding Spot"},
        "M9P":{"city":"Etobicoke", "tech":"Krystian Smith"},
        "M9R":{"city":"Etobicoke", "tech":"Krystian Smith"},
        "M9V":{"city":"Etobicoke", "tech":"Krystian Smith"},
        "M9W":{"city":"Etobicoke", "tech":"Krystian Smith"},
        "N0A":{"city":"Brantford", "tech":""},
        "N0B":{"city":"Kitchener", "tech":""},
        "N0C":{"city":"Dundalk", "tech":""},
        "N0E":{"city":"Brantford", "tech":""},
        "N0G":{"city":"Mount Forest", "tech":""},
        "N0H":{"city":"Owen Sound", "tech":""},
        "N0J":{"city":"Kitchener", "tech":""},
        "N0K":{"city":"Stratford", "tech":""},
        "N0L":{"city":"London", "tech":""},
        "N0M":{"city":"London", "tech":""},
        "N0N":{"city":"Sarnia", "tech":""},
        "N0P":{"city":"Windsor", "tech":""},
        "N0R":{"city":"Windsor", "tech":""},
        "N1A":{"city":"Dunnville", "tech":""},
        "N1C":{"city":"Guelph", "tech":""},
        "N1E":{"city":"Guelph", "tech":""},
        "N1G":{"city":"Guelph", "tech":""},
        "N1H":{"city":"Guelph", "tech":""},
        "N1K":{"city":"Guelph", "tech":""},
        "N1L":{"city":"Guelph", "tech":""},
        "N1M":{"city":"Fergus", "tech":""},
        "N1P":{"city":"Cambridge", "tech":""},
        "N1R":{"city":"Cambridge", "tech":""},
        "N1S":{"city":"Cambridge", "tech":""},
        "N1T":{"city":"Cambridge", "tech":""},
        "N2A":{"city":"Kitchener", "tech":""},
        "N2B":{"city":"Kitchener", "tech":""},
        "N2C":{"city":"Kitchener", "tech":""},
        "N2E":{"city":"Kitchener", "tech":""},
        "N2G":{"city":"Kitchener", "tech":""},
        "N2H":{"city":"Kitchener", "tech":""},
        "N2J":{"city":"Waterloo", "tech":""},
        "N2K":{"city":"Kitchener", "tech":""},
        "N2L":{"city":"Waterloo", "tech":""},
        "N2M":{"city":"Kitchener", "tech":""},
        "N2N":{"city":"Kitchener", "tech":""},
        "N2P":{"city":"Kitchener", "tech":""},
        "N2R":{"city":"Kitchener", "tech":""},
        "N2T":{"city":"Waterloo", "tech":""},
        "N2V":{"city":"Waterloo", "tech":""},
        "N2Z":{"city":"Kincardine", "tech":""},
        "N3A":{"city":"New Hamburg", "tech":""},
        "N3B":{"city":"Elmira", "tech":""},
        "N3C":{"city":"Cambridge", "tech":""},
        "N3E":{"city":"Cambridge", "tech":""},
        "N3H":{"city":"Cambridge", "tech":""},
        "N3L":{"city":"Brant", "tech":""},
        "N3P":{"city":"Brantford", "tech":""},
        "N3R":{"city":"Brantford", "tech":""},
        "N3S":{"city":"Brantford", "tech":""},
        "N3T":{"city":"Brantford", "tech":""},
        "N3V":{"city":"Brantford", "tech":""},
        "N3W":{"city":"Caledonia", "tech":""},
        "N3Y":{"city":"Simcoe", "tech":""},
        "N4B":{"city":"Delhi", "tech":""},
        "N4G":{"city":"Tillsonburg", "tech":""},
        "N4K":{"city":"Owen Sound", "tech":""},
        "N4L":{"city":"Meaford", "tech":""},
        "N4N":{"city":"Hanover", "tech":""},
        "N4S":{"city":"Woodstock", "tech":""},
        "N4T":{"city":"Woodstock", "tech":""},
        "N4V":{"city":"Woodstock", "tech":""},
        "N4W":{"city":"Listowel", "tech":""},
        "N4X":{"city":"Saint Marys", "tech":""},
        "N4Z":{"city":"Stratford", "tech":""},
        "N5A":{"city":"Stratford", "tech":""},
        "N5C":{"city":"Ingersoll", "tech":""},
        "N5H":{"city":"Aylmer", "tech":""},
        "N5L":{"city":"Port Stanley", "tech":""},
        "N5P":{"city":"St. Thomas", "tech":""},
        "N5R":{"city":"St. Thomas", "tech":""},
        "N5V":{"city":"London", "tech":""},
        "N5W":{"city":"London", "tech":""},
        "N5X":{"city":"London", "tech":""},
        "N5Y":{"city":"London", "tech":""},
        "N5Z":{"city":"London", "tech":""},
        "N6A":{"city":"London", "tech":""},
        "N6B":{"city":"London", "tech":""},
        "N6C":{"city":"London", "tech":""},
        "N6E":{"city":"London", "tech":""},
        "N6G":{"city":"London", "tech":""},
        "N6H":{"city":"London", "tech":""},
        "N6J":{"city":"London", "tech":""},
        "N6K":{"city":"London", "tech":""},
        "N6L":{"city":"London", "tech":""},
        "N6M":{"city":"London", "tech":""},
        "N6N":{"city":"London", "tech":""},
        "N6P":{"city":"London", "tech":""},
        "N7A":{"city":"Goderich", "tech":""},
        "N7G":{"city":"Strathroy", "tech":""},
        "N7L":{"city":"Chatham-Kent", "tech":""},
        "N7M":{"city":"Chatham-Kent", "tech":""},
        "N7S":{"city":"Sarnia", "tech":""},
        "N7T":{"city":"Sarnia", "tech":""},
        "N7V":{"city":"Sarnia", "tech":""},
        "N7W":{"city":"Sarnia", "tech":""},
        "N7X":{"city":"Sarnia", "tech":""},
        "N8A":{"city":"Wallaceburg", "tech":""},
        "N8H":{"city":"Leamington", "tech":""},
        "N8M":{"city":"Essex", "tech":""},
        "N8N":{"city":"Tecumseh", "tech":""},
        "N8P":{"city":"Tecumseh", "tech":""},
        "N8R":{"city":"Windsor", "tech":""},
        "N8S":{"city":"Windsor", "tech":""},
        "N8T":{"city":"Windsor", "tech":""},
        "N8V":{"city":"Windsor", "tech":""},
        "N8W":{"city":"Windsor", "tech":""},
        "N8X":{"city":"Windsor", "tech":""},
        "N8Y":{"city":"Windsor", "tech":""},
        "N9A":{"city":"Windsor", "tech":""},
        "N9B":{"city":"Windsor", "tech":""},
        "N9C":{"city":"Windsor", "tech":""},
        "N9E":{"city":"Windsor", "tech":""},
        "N9G":{"city":"Windsor", "tech":""},
        "N9H":{"city":"Windsor", "tech":""},
        "N9J":{"city":"Windsor", "tech":""},
        "N9K":{"city":"Tecumseh", "tech":""},
        "N9V":{"city":"Amherstburg", "tech":""},
        "N9Y":{"city":"Kingsville", "tech":""},
        "P0A":{"city":"Sundridge", "tech":""},
        "P0B":{"city":"Bracebridge", "tech":""},
        "P0C":{"city":"Bracebridge", "tech":""},
        "P0E":{"city":"Gravenhurst", "tech":""},
        "P0G":{"city":"Sudbury", "tech":""},
        "P0H":{"city":"North Bay", "tech":""},
        "P0J":{"city":"New Liskeard", "tech":""},
        "P0K":{"city":"Kirkland Lake", "tech":""},
        "P0L":{"city":"Timmins", "tech":""},
        "P0M":{"city":"Sudbury", "tech":""},
        "P0N":{"city":"Timmins", "tech":""},
        "P0P":{"city":"Sudbury", "tech":""},
        "P0R":{"city":"Sudbury", "tech":""},
        "P0S":{"city":"Sault Ste. Marie", "tech":""},
        "P0T":{"city":"Thunder Bay", "tech":""},
        "P0V":{"city":"Weagamow Lake", "tech":""},
        "P0W":{"city":"Kenora", "tech":""},
        "P0X":{"city":"Kenora", "tech":""},
        "P0Y":{"city":"Ingolf", "tech":""},
        "P1A":{"city":"North Bay", "tech":""},
        "P1B":{"city":"North Bay", "tech":""},
        "P1C":{"city":"North Bay", "tech":""},
        "P1H":{"city":"Huntsville", "tech":""},
        "P1L":{"city":"Bracebridge", "tech":""},
        "P1P":{"city":"Gravenhurst", "tech":""},
        "P2A":{"city":"Parry Sound", "tech":""},
        "P2B":{"city":"Garden Village", "tech":""},
        "P2N":{"city":"Kirkland Lake", "tech":""},
        "P3A":{"city":"Sudbury", "tech":""},
        "P3B":{"city":"Sudbury", "tech":""},
        "P3C":{"city":"Sudbury", "tech":""},
        "P3E":{"city":"Sudbury", "tech":""},
        "P3G":{"city":"Sudbury", "tech":""},
        "P3L":{"city":"Garson", "tech":""},
        "P3N":{"city":"Greater Sudbury", "tech":""},
        "P3P":{"city":"Hanmer", "tech":""},
        "P3Y":{"city":"Greater Sudbury", "tech":""},
        "P4N":{"city":"Timmins", "tech":""},
        "P4P":{"city":"Timmins", "tech":""},
        "P4R":{"city":"Timmins", "tech":""},
        "P5A":{"city":"Elliot Lake", "tech":""},
        "P5E":{"city":"Espanola", "tech":""},
        "P5N":{"city":"Kapuskasing", "tech":""},
        "P6A":{"city":"Sault Ste. Marie", "tech":""},
        "P6B":{"city":"Sault Ste. Marie", "tech":""},
        "P6C":{"city":"Sault Ste. Marie", "tech":""},
        "P7A":{"city":"Thunder Bay", "tech":""},
        "P7B":{"city":"Thunder Bay", "tech":""},
        "P7C":{"city":"Thunder Bay", "tech":""},
        "P7E":{"city":"Thunder Bay", "tech":""},
        "P7G":{"city":"Thunder Bay", "tech":""},
        "P7J":{"city":"Thunder Bay", "tech":""},
        "P7K":{"city":"Thunder Bay", "tech":""},
        "P7L":{"city":"Neebing", "tech":""},
        "P8N":{"city":"Dryden", "tech":""},
        "P8T":{"city":"Sioux Lookout", "tech":""},
        "P9A":{"city":"Fort Frances", "tech":""},
        "P9N":{"city":"Kenora", "tech":""}
    }        
};

export default config;
