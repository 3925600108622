import config from '../config';

// Utility function to handle API calls, check for 401 errors, and return data or display error messages
export const fetchWithAuth = async (url, options = {}) => {
  try {
    const response = await fetch(url, options);
    const data = await response.json();

    // Check for 401 Unauthorized error
    if (response.status === 401 || (data.code === 401 && data.name === 'Unauthorized')) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('state');
      redirectToServiceFusionOAuth();  // Redirect to the login screen
      return null;
    }

    // Check if response is successful and data has the expected `items` array
    if ((response.status === 200 || response.status === 201) && Array.isArray(data.items)) {
      return data;
    }

    // For other error statuses, display the error message
    showErrorMessage(data.code, data.name, data.message);
    return null;
  } catch (error) {
    console.error('Error making API call:', error);
    return null;
  }
};

// Function to redirect user to ServiceFusion OAuth2 login
export const redirectToServiceFusionOAuth = () => {
  const state = generateRandomState();  // Generate random state string
  localStorage.setItem('state', state);  // Store the state in localStorage

  // Redirect user to ServiceFusion OAuth2 authorization endpoint
  const authUrl = `${config.servicefusion.API.oauthUrl}?response_type=code&client_id=${config.servicefusion.API.clientId}&state=${state}`;
  window.location.href = authUrl;
};

// Function to generate a random state string
const generateRandomState = () => {
  return Math.random().toString(36).substring(2, 14);  // Generate a 12-character random string
};

// Function to display error messages for non-200 status responses
const showErrorMessage = (code, name, message) => {
  const errorContainer = document.createElement('div');
  errorContainer.innerHTML = `
    <h2>Error ${code} - ${name}</h2>
    <div>${message}</div>
  `;
  errorContainer.style.color = 'red';
  document.body.appendChild(errorContainer);
};
